.executive-analysis-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.4s ease; /* Smooth transition for hover effects */
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff; /* Default background color */
  border-radius: 10px; /* Optional for a polished look */
  cursor: pointer; /* Pointer cursor for interactivity */
}

.executive-analysis-card-container:hover {
  background-color: #f0f0f0; /* Change background color on hover */
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.1); /* Elevate shadow on hover */
  transform: scale(1.03); /* Slightly enlarge the card */
}

.executive-analysis-card-heading, .executive-analysis-card-count{
  font-family: 'lato', sans-serif;
  font-weight: 700;
  margin-left: 20px;
  margin: 24px 0 24px 20px;
  color: rgba(0, 0, 0, 1);
  
}

.executive-analysis-card-heading{
  font-size: 24px;
}

.executive-analysis-card-count{
  font-size: 34px;
}