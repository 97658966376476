/* MovieSlider.css */

.image-slider-container {
  width: 100%;
  /* margin: 20px auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.swiper {
  position: relative;
}

.swiper .swiper-slide {
  width: 100%; /* Set slide width to 100% to accommodate full image width */
}

.swiper .swiper-slide img {
  width: 100%;
  height: 18rem;
  border-radius: 10px;
  /* box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.6); */
}

.swiper-pagination {
  position: relative;
  display: flex; /* Display pagination dots as a flex container */
  justify-content: center; /* Center pagination dots horizontally */
  list-style: none; /* Remove default list style */
  padding: 0;
  margin: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: green; /* Set pagination dot color to green */
  opacity: 1; /* Set opacity to 1 for all pagination dots */
  width: 10px;
  height: 10px;
  border-radius: 50%; /* Make pagination dots circular */
  transition: background-color 0.5s; /* Add transition effect */
  margin: 0 5px; /* Adjust margin between pagination dots */
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--theme); /* Adjust active bullet color */
}
