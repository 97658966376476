.content-container {
  display: flex;
  justify-content: center;
}

.errorr {
  color: red;
  margin-left: 11rem;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.adddeveloper-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.adddeveloper-content {
  display: flex;
  align-items: center;
}
.MuiFormControl-root {
  margin-bottom: 16px;
}

.MuiOutlinedInput-input {
  padding: 10px 12px; /* Adjust padding as needed */
  font-size: 14px; /* Adjust font size as needed */
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em !important;
}
.developer-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 0.8rem; */
}
.arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 2rem;
}
