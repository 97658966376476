.Card-container {
  /* display: flex; */
  flex: 1;
  /* height: 123px !important; */
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  /* width: 310px; */
  
  margin-top: 10px;
  font-size: lato !important;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 5px;
  
  
}
.visit{
  display: flex;
  gap: 5px;
}

.developer{
 display: flex;
 gap: 5px;
 flex-direction: column;
}