.total-num-card-container {
  padding: 18px;
  border-radius: 10px;
  /* background-color: rgb(255, 255, 255); */
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease; /* Smooth transition for all properties */
}

.total-num-card-container:hover {
  /* background-color: #f0f0f0; Change background color on hover */
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.1); /* Slightly elevate the shadow */
  transform: scale(1.03); /* Slightly enlarge the card */
}

.total-num-card-content-right {
  margin-left: 20px;
}

.total-num-card-heading,
.total-num-card-number {
  font-family: "lato", sans-serif;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}

.total-num-card-heading {
  font-size: 20px;
}

.total-num-card-number {
  font-size: 24px;
}
