.member {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
}

.role-type {
  display: flex;
  justify-content: space-evenly;
}

.Addmanager-container {
  display: flex;

  justify-content: center;
  /* border:2px solid green; */
}

.form-group {
  margin: 1rem;
}

.s-select {
  display: flex;
  flex-direction: column;
  width: 100%;
 
  
}

.select-content {
  padding: 0.5rem;
  border-radius: 0.4rem;
  background-color: aliceblue;
  border-color: #ccc;
  font-family: inherit;
  font-weight: inherit;
  
}
