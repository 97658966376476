.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .css-11lq3yg-MuiGrid-root{
  width: min-content ;
} */

.css-ee20y-MuiPaper-root {
  height: 95vh;
}

.MuiInputBase-root {
  border-color: rgb(50, 69, 191) !important;
}

/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 100px !important;
 
  
  
} */
.css-10o2lyd-MuiStack-root {
  padding: 0px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  color: rgb(50, 69, 191);
}

/* .css-z3c6am-MuiFormControl-root-MuiTextField-root{
  min-width: 160px !important;
  padding:1px !important;

} */

.MuiTabs-indicator {
  background: rgb(50, 69, 191) !important;
}

/* .css-fedopx-MuiStack-root {
  width: 273px !important;
} */
