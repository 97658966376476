.detail-container {
  /* Container styling */
  padding: 1rem;
  width:100%;
  max-Width:500px;
}

.detail-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.detail-left {
  width: 45%;
  font-size: 1rem; 
  font-weight: 700;
}

.detail-colon {
  width: 10%;
  
}

.detail-value {
  width: 45%;
}

.error-message {
  color: red; 
}
